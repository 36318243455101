.cell-phone .text {
    animation: textvisibility 5s linear infinite;
}

.cell-phone .scan {
    animation: scanvisibility 5s linear infinite;
}

.cell-phone .scan .redline {
    animation: scanning 5s linear infinite;
}



@keyframes scanning {
    0% {
        transform: translateY(0px)
    }

    17% {
        transform: translateY(94px);
    }

    32% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(94px);
    }

    51% {
        visibility: hidden;
    }

    100% {
        visibility: hidden;
        transform: translateY(0px)
    }
}

@keyframes scanvisibility {
    0% {
        visibility: visible;
    }

    51% {
        visibility: hidden;
    }

    100% {
        visibility: hidden;
    }
}

@keyframes textvisibility {
    0% {
        visibility: hidden;
    }

    51% {
        visibility: hidden;
    }

    52% {
        visibility: visible;
    }

    100% {
        visibility: visible;
    }
}


@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.start {
    font-size: 22px;
    display: flexbox;
    position: relative;
    width: 150px;
    height: 150px;
    background: linear-gradient(to bottom, hotpink 0%, tomato 100%);
    border-radius: 50%;
    line-height: 150px;
    text-align: center;
    text-decoration: none;
    color: white;
}


.start::before {
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(239, 239, 239);
    opacity: 0;
    content: '';
    animation: pulse 1s infinite;
}


@keyframes pulse {
    from {
        transform: scale(1);
        opacity: .4;
    }

    to {
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes blastOut {
    from {
        transform: scale(0.9);
        opacity: .4;
    }

    to {
        transform: scale(10);
        opacity: 0;
    }
}

.QR_code {
    box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
}