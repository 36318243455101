.top_row,
.bottom_row {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.img_box {
    margin: 20px auto;
    flex: 1;
    display: flex;
    justify-content: center;
}

.img_box img {

    width: 100%;
    margin: 5px;
    height: auto;
    display: block;
}