.App {
    text-align: center;
}

.background {
    color: #EEEEEE;
    font-family: Arial, Helvetica, sans-serif;
    background-image: radial-gradient(circle at center center, rgb(71, 71, 71), rgb(8, 8, 8));
}

.flex_center {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


input[name='options'] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    opacity: 0;
}

label {
    width: 20rem;
    margin: 10px;
    display: inline-block;
    padding: 20px 20px;
    border: solid 1px;
    border-radius: 15px;
    color: #76ABAE;
    transition: box-shadow 1s, color 1s;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    text-align: center;
}

label:after {
    content: attr(data-icon);
    font-size: 25px;
}

input[name='options']:checked+label {
    box-shadow: 2px 2px 14px rgba(220, 220, 220, 0.755);
    color: #EEEEEE;
}

.imgStyles {
    width: 200px;
    height: auto;
    /* border-radius: 20px; */
    z-index: 3;
    filter: drop-shadow(0px 6px 12px rgba(50, 50, 93, 0.25)) drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.3));

}

.login-input {
    font-family: Arial, Helvetica, sans-serif;
    outline: none;
    color: #76ABAE;
    filter: drop-shadow(0px 6px 12px rgba(50, 50, 93, 0.25)) drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.3));
    background-color: rgb(71, 71, 71);
    border: none;
    border-radius: 5%;
    padding: 1vw;
    width: 20vw;
    transition: box-shadow 1s, color 1s;
}

.login-input:focus {
    transition: box-shadow 1s, color 1s;
    filter: grayscale(1);
}

.login-input:disabled {
    filter: grayscale(1);
}